/* NAVBAR */

nav {
  /* position: relative; */
  background-color: #ffffff;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 3px;
  top: 0;
  overflow: hidden;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-content: stretch;
  z-index: 2;

}

nav a, nav .dropbtn {
  display: inline-block;
  color: black;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
  flex: 1 auto;
  transition: 0.5s;
}

  /* Change the color of links on hover */
  nav a:hover  {
    text-shadow: 0 0 30px #04AA6D;
  }