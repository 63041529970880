/* Looper styles */
@keyframes slideAnim {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-100%);
  }
}

.looper {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  align-items: center; /* Align items to the center vertically */
  justify-content: center; /* Center the looper container horizontally */
  box-sizing: border-box;
}

.looper__innerList {
  display: flex;
  justify-content: flex-start;
  width: fit-content;
  position: relative; 
}

.looper__innerList[data-animate="true"] .looper__listInstance {
  animation: slideAnim 35s linear infinite;   
}

.looper__listInstance {
  display: flex;
  width: max-content;
  animation: none;
}

.looper:hover .looper__innerList[data-animate="true"] .looper__listInstance {
  animation-play-state: paused;
}

.looper__innerList img {
  height: 450px;
  margin: 0 40px;
}

  /* .looper:before, .looper:after {
    position: absolute;
    bottom: 0;
    width: 100px;
    height: 100%;
    content: "";
    z-index: 2;
  }

  .looper:before {
    left: 0;
    background: linear-gradient(to left, rgba(255, 255, 255, 0), white);
  }

  .looper:after {
    right: 0;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), white);
  } */
  
  /* Example content styles */

  .contentBlock {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 120px;
    margin: 10px;
    padding: 16px; 
    font-weight: 600;
    text-align: center;
    border-radius: 16px;
  }
  .contentBlock--two {    
    color: var(--green);  
    font-size: 48px;
    background: rgba(255, 255, 255, 0.05);  
  }