.leader-container {
    width: auto;
    /* background-color: blueviolet; */
    display: grid;
    /* justify-items: stretch; */
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    max-width: 100%;
    /* justify-items: center;
    align-items: center; */
    gap: 15px;
}

 .leader-container div {
    background-color: rgb(245, 245, 245);
    text-align: center;
    display: grid;
    grid-template-rows: 9fr 1fr;
    align-items: center;
}

/* .leader-container div:nth-child(6) {
    grid-column: 2 / span 2;
  } */

.leader-container div img{
    position: relative;
    width: 100%;
    height: auto;
}

.leader-container div i {
    /* padding-top: 15px; */
    color: rgb(136, 124, 124);
}
