main {
    padding-bottom: 0%;
    /* padding-top: 0%; */
}

#landing {
    display: flex;
    flex-direction: column;
    /* background-color: azure; */
    /* max-height: 200%; */
    min-height: 200%;
    margin-top: -50px;
    justify-content: space-between;
}

#full-page {
    height: 100vh;
    width: auto;
    /* background-color: blueviolet; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* padding-top: 50px; */
}

#landing .logo {
    height: 150px;
    display: block;  
    margin-left: auto;
    margin-right: auto;
}

 #logo_container {
    justify-content: center;
    margin-top: auto;
    display: flex;
    bottom: 0%;
}

.landing-element {
    display: block;
    flex-shrink: 0;
}