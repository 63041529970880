.cards-container {
    background-color: black;
    max-width: 100%;
  }
  
  /* .cards_container {
    display: flex;
    flex-flow: column;
    align-items: center;
    flex-wrap: wrap;
    max-width: auto;
    /* width: 30%;
    margin: 0 auto;
    background-color: bisque;
  } */
  
  .cards_items {
    position: relative;
    margin-top: 50px;
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }


  /* CARD ITEM */

  .cards_item {
    position: relative;
    width: 300px;
    height: 265px; /* Fixed height for the card */
    display: flex;
    flex-direction: column;
    margin-bottom: 3%;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    overflow: hidden; /* Ensure content does not overflow the fixed height */
  }
  
  .cards_item_pic-wrap {
    width: 100%;
    height: 205px;
    /* flex: 1 1 auto; */
    overflow: hidden;
    /* display: flex; */
    justify-content: center;
  }
  
  .cards_item_img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.2s linear;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
    
  .cards_item_img:hover {
    transform: scale(1.1);
  }
  
  .cards_item_info {
    top: 0;
    padding-top: 10px;
    padding-left: 5%;
    padding-right: 5%;
    word-wrap: break-word;
    overflow: hidden;
  }
  
  .cards_item_text {
    color: black;
    display: inline;
    font-size: 16px;
    text-align: center;
  }