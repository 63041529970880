p {
    text-align: justify;
}

h1.formation_page_name {
    text-decoration: none;
    text-align: left;
    margin: 0;
    margin-top: 5px;
    margin-top: -40px;
    color: #ffffff;
    text-shadow: #000000;

    /* background-color: #ffffff;
    color: black; */
}

h1.formation_page_name span {
    background-color: rgba(255, 255, 255, 0.523);
    padding-left: 10px;
    padding-right: 10px;
}

.modal-overlay {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    top: 0;
    left: 0;
  }
  
  .modalContainer {
    max-width: 600px;
    max-height: 650px;
    width: 100%;
    position: fixed;
    top: 85px;
    left: 525px;
    /* padding: 10px; */
    /* transform: translate(-50%, -50%);
    margin: auto,auto,auto,auto; */
    /* margin-left: 50%;
    margin-right: 50%; */
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.75);
    border-radius: 10px;
    overflow-y: auto;
  }
  
  img.formation_page_img {
    position: relative;
    width: auto;
    height: 25%;
    object-fit: cover;
    z-index: -1;

    /* border-top-left-radius: 8px;
    border-bottom-left-radius: 8px; */
  }

  .formation_page_content {
    padding-left: 20%;
    padding-right: 5%;
  }

  .closeBtn {
    position: relative;
    text-align: right;
    padding-right: 15px;
    font-size: 30px;

    /* top: 8px;
    right: 8px; */
  } 
  
  /* .modalRight {
    width: 100%;
  }
  
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin-top: 3rem;
    padding: 1rem 2rem;
  }
  
  .btnContainer {
    display: flex;
    padding: 1rem 1rem;
  }
  .btnContainer button {
    width: 100%;
    margin: .5rem;
    padding: 16px 0;
    border: 1px solid #411b57;
  }
  
  .btnPrimary {
    background-color: #411b57;
    color: white;
  }
  
  .btnOutline {
    background-color: white;
    color: #411b57;
  }
  
  .bold {
    font-weight: 600;
  }
  
  @media screen and (max-width: 500px) {
    .modalContainer {
      flex-direction: column;
      top: 0;
      left: 0;
      transform: none;
      width: 100%;
      height: 100vh;
    }
    img {
      width: 100%;
      max-height: 70vh;
      object-fit: cover;
    }
  
    .heading {
      margin: 1rem;
    }
  }
   */