form {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background-color:#d5dcd9; */
  }
  
  .form-container {
    width: 400px;
    min-height: 400px;
    height: auto;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.4);
    display: flex;
    flex-direction: column;
  }

  .form-container .pwd-validator {
    font-size: 10px;
  }
  
  .form-container .header {
    flex: 20%;
    display: grid;
    place-items: center;
  }
  
  .form-container .body {
    flex: 60%;
    margin-left: 20%;
    margin-right: 20%;
    margin-top: 20px;
    margin-bottom: 20px;
    /* background-color: #d5dcd9; */
    justify-content: center;
  }
  
  .form-container .footer {
    /* flex: 20%; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 20%;
    margin-right: 20%;
    /* margin-right: 10%; */
    margin-bottom: 5px;
  }
  
  .form-container .footer div {
    /* background-color: blue; */
    text-align: center;
    width: 100%;
  }
  
  .form-container .footer button {
    border-radius: 70%;
    width: 40px;
    height: 40px;
    background-color: white;
    font-weight: bold;
    color: #04AA6D;
    border: 0 none;
    cursor: pointer;
    padding: 5px;
    margin: 10px 5px;
    margin: 5px;
    box-shadow: 1px 1px 3px darkslategray;
    transition: all 0.2s ease;
    display: inline-block;
    text-align: center;
  }

  /* .form-container .footer button:active {
    background-color: #d5dcd9;
    transform: translateY(1px);
  } */

  .form-container .footer .submit {
    border-radius: 0;
    width: fit-content;
    height: 40px;
    background-color: #04AA6D;
    font-weight: bold;
    color: white;
    border: 0 none;
    cursor: pointer;
    padding: 5px;
    margin: 10px 5px;
    margin: 5px;
    box-shadow: 1px 1px 3px darkslategray;
    transition: all 0.2s ease;
    text-align: center;
  }
  
  /* PROGRESS BAR */
  .progressbar {
    width: 400px;
    height: 10px;
    background-color: white;
    margin-bottom: 50px;
  }
  
  .progressbar div {
    width: 33.3%;
    height: 100%;
    background-color: #04AA6D;
  }

  /*/////////*/
  /* BUTTONS */
  /*/////////*/

  button.btn {
    text-align: center;
    height: 40px;
    cursor: pointer;
    border: 0 none;
    padding: 10px;
    font-size: 15px;
    border-radius: 5px;
  }

  button.btn:disabled {
    opacity: 60%;
  }

  button.secondary {
    border: 2px solid lightgrey;
  }

  button.secondary:hover {
    background-color: lightgray;
    transition: background-color 0.3s ease;
  }

  button.primary {
    background-color: white;
    font-weight: bold;
    color: #006633;
    border: 2px solid lightgrey;
  }

  button.primary:hover {
    border-color: #04AA6D;
    transition: 0.3s ease;
  }
  
  button.submit {
    color: white;
    background-color: #006633;
    font-weight: bold;
  }

  button.submit:hover {
    background-color: #04AA6D;
    transition: background-color 0.3s ease;
  }