/* useful for styling: 
https://www.w3schools.com/howto/howto_css_dropdown_navbar.asp */

/* ul {
    list-style: none;
  }
  
a {
    text-decoration: none;
}

.dropdown-menu {
    position: absolute;
    top: 100px;
    right: 20px;
    background-color: #fff;
    border-radius: var(--border-radius);
    padding: 10px 20px;
    width: 200px;
}

.dropdown-menu::before {
    content: '';
    position: absolute;
    top: -5px;
    right: 20px;
    height: 20px;
    width: 20px;
    background: var(--secondary-bg);
    transform: rotate(45deg);
}

.dropdown-menu.active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    transition: var(--speed) ease;
}

.dropdown-menu.inactive {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-20px);
    transition: var(--speed) ease;
}

.dropdown-menu ul li {
    padding: 10px  0;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
}

.dropdown-menu ul li:hover a {
    color: rgb(212, 33, 9);
    cursor: pointer;
}

.dropdown-menu ul li:hover img {
    opacity: 1;
    cursor: pointer;
    }

.dropdownItem {
    display: flex;
    margin: 10px auto;
}

.dropdownItem img {
    max-width: 20px;
    margin-right: 10px;
    opacity: 0.5;
    transition: var(--speed);
}

.dropdownItem a {
    max-width: 100px;
    margin-left: 10px;
    transition: var(--speed);
} */

/* .dropdown {
    position: relative;
} */

/* .menu {
    list-style: none;
    padding: 0;
    margin: 0;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

/* .menu > li > a > button,  .menu > li > button{
    width: 100%;
    height: 100%;
    text-align: left;

    background: none;
    color: inherit;
    border: none;
    padding: 5px;
    margin: 0;
    font: inherit;
    cursor: pointer;
} */

/* .dropdown .dropbtn {
    font-size: 16px;  
    border: none;
    outline: none;
    color: white;
    padding: 14px 16px;
    background-color: inherit;
    font-family: inherit;
    margin: 0;
  } */

/* .dropdown-content {
    list-style: none;
    padding: 0;
    margin: 0;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: absolute;
    min-width: 160px;
    z-index: 3;
} */
  
/* .dropdown-content a {
    float: none;
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
} */

/* .dropdown-content > li {
    margin: 0;
}

.dropdown-content > li:hover {
    background-color: lightgray;
} */

/* BRAND NEW */

.dropdown {
    float: left;
    overflow: hidden;
  }
  
  .dropdown .dropbtn {
    font-size: 16px;  
    border: none;
    outline: none;
    /* color: black; */
    padding: 14px 16px;
    /* background-color: inherit; */
    font-family: inherit;
    margin: 0;
    background-color: #006633;
    color: white;
  }
  
  .dropdown-content {
    display: none;
    list-style: none;
    padding: 0;
    margin: 0;
    position: absolute;
    background-color: #f9f9f9;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 3;
  }
  
  .dropdown-content a {
    float: none;
    color: black;
    /* padding: 12px 16px; */
    text-decoration: none;
    display: block;
    cursor: pointer;
    text-align: left;
  }
  
  .dropdown-content a:hover {
    background-color: #ddd;

  }
  
  .dropdown:hover .dropdown-content {
    display: block;
  }