/* EVENT PAGE */

.text {
  line-height: 1.6;
}

iframe {
  width: auto;
  min-width: 50%;
  height: 360px;
}

/* end of EVENT PAGE */

.event-container {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .event-container h3.year {
    font-size: 25px;
    text-align: left;
    border-bottom: 1px solid #b1b1b1;
    margin-top: 0%;
    margin-bottom: 0%;
    padding-left: 5px;
  }
  
  .event-container .event-item {
    box-shadow: 0 4px 16px -8px rgba(0, 0, 0, 0.4);
    display: flex;
    border-radius: 8px;
    margin: 32px 0;
  }
  
  .event-item .event-left {
    /* background: #222; */
    background: #006633;;
    min-width: 82px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #eee;
    padding: 8px 48px;
    font-weight: bold;
    text-align: center;
    border-radius: 8px 0 0 8px;
    /* font-family: "Roboto", sans-serif; */
  }
  
  .event-item .event-left .date {
    font-size: 56px;
  }
  
  .event-item .event-left .month {
    font-size: 16px;
    font-weight: bold;
    /* text-transform: uppercase; */
  }
  
  .event-item .event-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 24px;
    /* background-color: aqua; */
    width: 100%;
  }
  
  .event-item .event-right h3.event-title {
    /* font-size: 24px; */
    margin: 24px 0 10px 0;
    color: #222;
    font-weight: bolder;
    text-align: center;
    /* text-transform: uppercase; */
  }

  /* .event-item .event-right .event-description {
    padding: 0;
    margin: 0;
    font-size: 12px;
  } */
  
  .event-item .event-right .event-props {
    display: flex;
    /* margin-top: -25px;
    margin-bottom: -25px; */
    justify-content: center;
  }

  .event-item .event-right .event-props .event-timing {
    background: #eee;
    /* display: flex; */
    align-items: center;
    justify-content: center;
    /* width: 100px; */
    padding: 8px;
    border-radius: 16px;
    margin: 0px 3px;
    font-size: 13px;
  }
  
  @media (max-width: 550px) {
    .event-item {
      flex-direction: column;
    }
  
    .event-item .event-left {
      padding: 0;
      border-radius: 8px 8px 0 0;
    }
  
    .event-item .event-left .event-date .date,
    .event-item .event-left .event-date .month {
      display: inline-block;
      font-size: 24px;
    }
  
    .event-item .event-left .event-date {
      padding: 10px 0;
    }
  }

  /* UPCOMING EVENTS */

  .upcoming-events-items {
    position: relative;
    padding: 0;
    /* margin-top: 50px; */
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  .upcoming-event-item {
    position: relative;
    /* display: flex; */
    margin: 20px;
    padding: 0;
    /* flex-direction: column; */
    /* flex: 1 1 auto; */
    /* margin: 0 1rem; */
    /* margin-bottom: 3%;
    border-bottom-left-radius: 10px; */
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  }

  img.upcoming-event-img {
    width: 300px;
    height: 200px;
    object-fit: cover;
    object-position: 50% 0;
    /* transition: all 0.2s linear;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px; */
    border-radius: 10px;
    display: block;
    /* margin-left: auto;
    margin-right: auto; */
  }

  .upcoming-event-placeholder {
    width: 300px;
    height: 200px; 
    border-radius: 10px;
    background-color: #CCCCCC;
    display: flex;
    align-items: center;
    justify-content: center;
    /* line-height: 20px;  */
  }

  .upcoming-event-placeholder .title-text {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 20px;
    font-family: 'Marcellus SC';
    color: #222;
  }

  .upcoming-event-item .overlay {
    position: absolute;
    border-radius: 10px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    display: flex;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .upcoming-event-item .overlay .text {
    color: white;
    font-size: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
  }

  .upcoming-event-item .overlay h3 {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 100%;
    font-family: 'Marcellus SC';
    color: white;
  }
  
  .upcoming-event-item:hover .overlay {
    opacity: 1;
  }