.form-window {
    width: 100%;
    margin-bottom: 50px;
}

h2 {
    padding-bottom: 15px;
}

.form-window-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 0%;
    margin-bottom: -15px;
}

/* .form-window button.primary {
    border-radius: 15%;
    width: fit-content;
    height: 40px;
    background-color: white;
    font-weight: bold;
    color: #04AA6D;
    border: 0 none;
    cursor: pointer;
    padding: 5px;
    margin: 10px 5px;
    margin: 5px;
    box-shadow: 1px 1px 3px darkslategray;
    transition: all 0.2s ease;
    text-align: center;
}

.form-window button.submit {
    border-radius: 15%;
    width: fit-content;
    height: 40px;
    background-color: #04AA6D;
    font-weight: bold;
    color: white;
    border: 0 none;
    cursor: pointer;
    padding: 5px;
    margin: 10px 5px;
    margin: 5px;
    box-shadow: 1px 1px 3px darkslategray;
    transition: all 0.2s ease;
    text-align: center;
} */

form.user-data-page {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
}

.full-width-group {
    grid-column: 1 / 3;
}
