.info-bubble-container {
    position: relative;
    display: inline-block;
}

.info-bubble {
    position: absolute;
    /* bottom: 100%; */
    left: 50%;
    transform: translateX(-50%);
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    white-space: nowrap;
    z-index: 1;
    width: auto; /* Ensures the width fits the text */
    max-width: 300px; /* Optional: max-width to prevent it from becoming too wide */
    text-align: center; /* Center-align text */
}
  