.address-form {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
}

.address-form .full-width-group {
    grid-column: 1 / 5;
}

.address-form .quarter-right-group {
    grid-column: 2 / 5;
}

.address-form .quarter-left-group {
    grid-column: 1 / 4;
}