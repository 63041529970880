/* SIDEBAR */

#side-bar {
    background-color: #006633;
    color: white;
    width: 8%;
    height: 100%;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    top: 0;
    left: 0;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* align-self: center; */
  }
  
  #side-bar img#side-bar-logo {
    width: 100%;
    top: 0;
    /* background-color: blueviolet; */
  }

  #hide-item {
    color: #006633;
  }

  /* #logo-title p{
    white-space: normal;
  } */

  #logo-title {
    overflow: hidden;
    width: fit-content;
    height: auto; /* Change this to auto to allow content to determine height */
    display: inline-flex;
    margin-top: 15px;
  }
  
  #logo-title img {
    width: 100%;
    max-height: 90%;
    object-fit: contain; /* Use contain to maintain aspect ratio without stretching */
    align-self: center;
  }
  
  #footer {
    bottom: 0;
    /* background-color: aquamarine; */
  }
  
  #footer p {
    text-align: center;
  }

  .side-bar-child {
    display: block;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    /* white-space: nowrap; */
  }
  