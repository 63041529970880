main .content {
    text-align: justify;
    text-justify: inter-word;
}

main .footer button {
    border-radius: 70%;
    width: 40px;
    height: 40px;
    background-color: white;
    font-weight: bold;
    color: #04AA6D;
    border: 0 none;
    cursor: pointer;
    padding: 5px;
    margin: 10px 5px;
    margin: 5px;
    box-shadow: 1px 1px 3px darkslategray;
    transition: all 0.2s ease;
    display: inline-block;
    text-align: center;
  }

main .footer button:active {
    background-color: #d5dcd9;
    transform: translateY(1px);
}

main .footer div {
    width: fit-content;
    float: right;
    /* display: flex;
    flex-direction: row;
    justify-content: space-between; */
}

main a:link { 
    text-decoration: none; 
    color: #04AA6D;
  }

main a:visited { 
    text-decoration: none; 
    color: black;
  }
  
main a:hover { 
    text-decoration: none; 
  }
  
main a:active { 
    text-decoration: none; 
    color: #535e5a;
  }

  div h2 {
    padding-top: 25px;
  }

  p {
    line-height: 1.6;
  }