/* MAIN CONTAINER */

main {
    margin: 0;
    position: relative;
    padding: 50px;
    height: 100%;
    width: 70%;
    /* width: 1200px; */
    background-color: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 4px;
    align-self: center;
    overflow-y: auto;
    flex: 1;
  }