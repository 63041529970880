.info-container {
    background-color: lightgray;
    border-radius: 5px;
    padding: 20px;
    display: flex;
    align-items: center;
  }
  
  .info-content {
    display: flex;
    align-items: center;
  }
  
  .info-text {
    margin-left: 15px;
  }
  
  .info-text p {
    margin: 5px 0;
  }