.filter-container {
    background-color: #f5f5f5;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin: 20px 0;
}

/* Filter Content Styles */
.filter-content {
    display: grid;
    grid-template-columns: 5fr 5fr 1fr;
    column-gap: 20px;
    align-items: center;
    justify-content: space-between;
}

.filter-content button{
    height: 50px;
    font-size: 15px;
    font-family: arial, verdana;
}

/* 
.react-select__control {
    border: 1px solid #004b2c; 
    border-radius: 4px;
    padding: 5px;
    min-height: 45px;
    font-size: 1em;
    box-shadow: none;
    background-color: #ffffff;
}

.react-select__menu {
    border: 1px solid #004b2c;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
} */


/* Responsive Adjustments */
@media (max-width: 768px) {
    .filter-content {
        grid-template-columns: 1fr;
        row-gap: 10px;
    }

    .primary {
        width: 100%;
        margin-top: 10px;
    }
}
