/*
@import url(https://fonts.googleapis.com/css?family=Montserrat);
@import url('http://fonts.cdnfonts.com/css/marcellus-sc');
*/

@font-face {
  font-family: 'Marcellus SC';
  src: url('/public/fonts/MarcellusSC-Regular.woff2') format('woff2'),
      url('/public/fonts/MarcellusSC-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Libre Baskerville';
  src: url('/public/fonts/LibreBaskerville-Italic.woff2') format('woff2'),
      url('/public/fonts/LibreBaskerville-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Libre Baskerville';
  src: url('/public/fonts/LibreBaskerville-Regular.woff2') format('woff2'),
      url('/public/fonts/LibreBaskerville-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Libre Baskerville';
  src: url('/public/fonts/LibreBaskerville-Bold.woff2') format('woff2'),
      url('/public/fonts/LibreBaskerville-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

/* FONTS UNTIL HERE */

body {
  margin: 0%;
  padding: 0%;
  background-image: url("/public/img/logo_drop_shadow_v1.png");
  background-size: cover;
  font-family: 'Libre Baskerville', arial, verdana;
  background-repeat: no-repeat;
  background-size: 40%;
  background-position: -18% -7%; 
  position: fixed;
}

h1 {
  text-align: center;
}

/* LAYOUT */

#vertical-wrapper {
  position: relative;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  flex: 1;
}

#root {
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  flex-direction: row;
  align-content: stretch;
}