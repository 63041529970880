.formInput {
    display: flex;
    flex-direction: column;
    /* background-color: aquamarine; */
}

/* .hFormContainer {
    display: flex;
    flex-direction: row;
    justify-items: center;
} */

.formInput>label {
    color: gray; 
    transform-origin: 0 0;
    transform: scale(.85);
    /* font-size: 12px; */
    /* background-color: aqua; */
    width: 100%;
    /* margin-bottom: -3px; */
}

.formInput span.form-input-state {
    color: red;
    font-size: 12px;
    padding-top: 8px;
}

/* .formInput input:focus ~ label,
.formInput input:valid ~ label,
.formInput select:focus ~ label,
.formInput select:valid ~ label {
    transform: translateY(-55px) scale(.8);
    margin-left: 0px;
} */

.formInput input[type=text],
.formInput input[type=password],
.formInput input[type=datetime-local], 
.formInput select {
    border: .25px solid #535e5a;
    border-radius: 5px;
    width: 100%;
    box-sizing: border-box;
    height: 50px;
    font-size: 15px;
    padding: 15px;
    font-family: arial, verdana;
    font-size: 15px;
}

input:focus, input:hover, 
select:focus, select:hover,
textarea:focus, textarea:hover {
  outline: 0.5px solid #04AA6D;
  transition: 0.3s ease;
}

.formInput input.error {
    outline: 0.5px solid red;
}

span.icons {
    position: absolute;
}

span.icons * {
    position: relative;
    cursor: pointer;
    font-size: 25px;
    top: 32px;
    left: 205px;
    background-color: white;
}
